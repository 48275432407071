import React, { useEffect } from 'react'
import api from '../../services/api'
import { useDispatch } from 'react-redux'
import { updateCoffees } from '../../state/reducers/coffees'
import { getQueryVariable } from '../../utils/location'
import { showLoading, hideLoading } from '../../state/reducers/loading'

import Layout from '../../components/layout'
import Container from '../../components/container'
import Title from '../../components/title'
import Videos from '../../components/videos'
import Packages from '../../components/packages'
import HowTo from '../../components/howTo'
import Perks from '../../components/perks'
import Extras from '../../components/extras'
import Cta from '../../components/cta'

const Simulator = ({ params }) => {
  const dispatch = useDispatch()

  const requestCoffees = () => {
    dispatch(showLoading('Carregando...'))

    api.get('/coffees').then((response) => {
      if(response.data.coffee_beans){
        let ids = getQueryVariable('ids')
        if(ids) ids = ids.split(',')

        const filteredCoffees = response.data.coffee_beans.filter((c) => {
          return ids.includes(c.id.toString())
        })

        dispatch(updateCoffees(filteredCoffees))
      }

      dispatch(hideLoading())
    }).catch(() => {
      dispatch(hideLoading())
    })
  }

  useEffect(() => {
    requestCoffees()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Layout>
      <Container>
        <Title />
        <Videos />
        <Packages />
        <HowTo />
        <Perks />
        <Extras />
        <Cta />
      </Container>
    </Layout>
  )
}

export const Head = () => {
  return(
    <title>
      Preparamos a sua caixinha com muito carinho
    </title>
  )
}

export default Simulator
